// Import dependencies
import React from "react";

// Import components
import { ParagraphText, TitleText } from "../../styled-components/UILibrary";

// Import styles
import "./steps-indicator.css";
import { Constants } from "../../constants/Constants";

import Tick from "../../assets/icons/tick-white.svg";
import HeaderSeparator from "../separators/headerSeparator";

/*
    Process indicator for Steps pages in
    the checkout process
*/
const StepsIndicator = ({ state }) => (
  <>

    <div className="steps-indicator__container">
      {/* Circle one */}
      <div
        className="steps-indicator__circle-container"
      >
        <div
          className="steps-indicator__circle"
          style={{ backgroundColor: state.first.circle }}
        >
          {state.first.circle === Constants.mainGreen ? (
            <Tick className="steps-indicator__icon" />
          ) : (
            <TitleText subTitle colour="#ffffff" noShadow>

            </TitleText>
          )}
        </div>
        <ParagraphText className="step-indicator-title" separator colour={state.first.title}>
          Details
        </ParagraphText>
      </div>

      <div
        className="steps-indicator__line"
        style={{ backgroundColor: state.line1.colour }}
      />

      {/* Circle two */}
      <div
        className="steps-indicator__circle-container"
      >
        <div
          className="steps-indicator__circle"
          style={{ backgroundColor: state.second.circle }}
        >
          {state.second.circle === Constants.mainGreen ? (
            <Tick className="steps-indicator__icon" />
          ) : (
            <TitleText subTitle colour="#ffffff" noShadow>

            </TitleText>
          )}
        </div>
        <ParagraphText className="step-indicator-title" separator colour={state.second.title}>
          Service
        </ParagraphText>
      </div>

      <div
        className="steps-indicator__line"
        style={{ backgroundColor: state.line2.colour }}
      />

      {/* Circle three */}
      <div
        className="steps-indicator__circle-container"
      >
        <div
          className="steps-indicator__circle"
          style={{ backgroundColor: state.third.circle }}
        >
          {state.third.circle === Constants.mainGreen ? (
            <Tick className="steps-indicator__icon" />
          ) : (
            <TitleText subTitle colour="#ffffff" noShadow>

            </TitleText>
          )}
        </div>
        <ParagraphText className="step-indicator-title" separator colour={state.third.title}>
          Get Paid
        </ParagraphText>
      </div>

      <div
        className="steps-indicator__line"
        style={{ backgroundColor: state.line3.colour }}
      />

      {/* Circle four */}
      <div
        className="steps-indicator__circle-container"
      >
        <div
          className="steps-indicator__circle"
          style={{ backgroundColor: state.fourth.circle }}
        >
          {state.fourth.circle === Constants.mainGreen ? (
            <Tick className="steps-indicator__icon" />
          ) : (
            <TitleText subTitle colour="#ffffff" noShadow>

            </TitleText>
          )}
        </div>
        <ParagraphText className="step-indicator-title" separator colour={state.fourth.title}>
          Complete
        </ParagraphText>
      </div>
    </div>
  </>
);

export default StepsIndicator;
